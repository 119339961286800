// Here you can add other styles
body {
  width: 100vw;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-calendar {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}

.close-calendar {
  font-size: 24px;
  padding: 20px;
  padding-top: 0;

  &:hover {
    cursor: pointer;
  }
}

.dropdown.show-calendar .dropdown-calendar {
  display: block;
}

.receipt-preview {
  padding-top: 50px;
  color: #000;

  p {
    font-size: 20px;
  }

  table {
    font-size: 20px;
  }
}

.inactive-label {
  color: red;
  font-weight: bold;
}

.customer-label {
  color: green;
  font-weight: bold;
}

.orgchart-container {
  .orgchart {
    min-height: 100%;
    min-width: 100%;

    .oc-node {
      .position {
        box-sizing: border-box;
        background-color: #215a88;
        color: #fff;
        width: 150px;
        margin: 0;
      }

      .fullname {
        box-sizing: border-box;
        color: #215a88;
        background-color: #fff;
        width: 150px;
        border: 1px solid #215a88;
        margin: 0;
      }

      &:hover {
        background-color: none;
      }

      &.selected {
        background-color: transparent;
      }

      .see-children-buttons {
        cursor: pointer;
      }
    }
  }
}

.print-header {
  display: none;
}

.payment-method-report {
  .print-header {
    display: block;
  }
}
